import React, {useEffect, useContext} from "react";
import {AuthContext} from "../../context/AuthContext";
import {HiOutlineSun} from "react-icons/hi";
import {BsFillMoonFill} from "react-icons/bs";

const ThemeChanger = () => {
    const {darkTheme, setDarkTheme} = useContext(AuthContext);
    const handleToggle = () => setDarkTheme(!darkTheme);

    useEffect(() => {
        if (darkTheme) {
            document.documentElement.setAttribute("data-theme", "dark");
            localStorage.setItem("theme", "dark");
        } else {
            document.documentElement.removeAttribute("data-theme");
            localStorage.setItem("theme", "light");
        }
    }, [darkTheme]);

    return (
        <div>
            {darkTheme !== undefined && (
                <button
                    className="dark-theme"
                    onClick={handleToggle}
                >
                    {!darkTheme ? (
                        <BsFillMoonFill size={25} className="text-white"/>
                    ) : (
                        <HiOutlineSun size={30} className="text-white"/>
                    )
                    }
                </button>
            )}
        </div>
    );
};
export default ThemeChanger;
