import React from "react";
import { render } from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./assets/scss/main.scss";

import { store } from "./redux/store";
import { Provider } from "react-redux";
import "bootstrap/dist/js/bootstrap.min";
import "rc-pagination/assets/index.css";
import "react-modal-video/scss/modal-video.scss";

const Root = () => (
  <Provider store={store}>
    <App />
  </Provider>
);

render(<Root />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
