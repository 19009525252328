import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { menuContent } from "../../fake-data/MobileData";
import { menuContentLogin } from "../../fake-data/MobileDataLogin";

const FooterList = () => {
  const { user } = useSelector((state) => state.auth, shallowEqual);

  return (
    <ul>
      {(!user ? menuContent : menuContentLogin).map((item, i) => (
        <li key={i}>
          <Link to={item.routerPath}>{item.name}</Link>
        </li>
      ))}
    </ul>
  );
};

export default FooterList;
