import React from "react";

const socialList = [
  {
    iconName: "socicon-facebook",
    link: "https://www.facebook.com/githubit",
    className: "facebook"
  },
  {
    iconName: "socicon-linkedin",
    link: "https://linkedin.com/company/githubit",
    className: "linkedin-icon"
  },
];

const SocialTwo = () => {
  return (
    <>
      {socialList.map((val, i) => (
        <a
          className={`ptf-social-icon ptf-social-icon--style-3 ${val.className}`}
          target="_blank"
          rel="noopener noreferrer"
          href={val.link}
          key={i}
        >
          <i className={val.iconName} />
        </a>
      ))}
    </>
  );
};

export default SocialTwo;
