export const menuContentLogin = [
  // {
  //   name: "Activate project",
  //   routerPath: "/activator",
  // },
  {
    name: "About us",
    routerPath: "/about-us",
  },
  // {
  //   name: "Contact",
  //   routerPath: "/contact",
  // },
  {
    name: "Career",
    routerPath: "/career",
  },
  // {
  //   name: "Open ticket",
  //   routerPath: "/login",
  // },
];
