import React from "react";
import Pricing from "../Pricing/Pricing";

const PricePlan = ({ data }) => {
  return (
    <section>
      <div
        className="ptf-spacer"
        style={{ "--ptf-xxl": "8.75rem", "--ptf-md": "4.375rem" }}
      />
      <div className="container">
        <div className="ptf-animated-block" data-aos="fade" data-aos-delay="0">
          <h2 className="h2 large-heading text-white">Pricing & Plan</h2>
        </div>
        <div
          className="ptf-spacer"
          style={{ "--ptf-xxl": "3.75rem", "--ptf-md": "1.875rem" }}
        />
      </div>
      <div className="container">
        <div className="row" style={{ "--bs-gutter-x": "2rem" }}>
          <Pricing data={data} />
        </div>
      </div>
      <div
        className="ptf-spacer"
        style={{ "--ptf-xxl": "8.75rem", "--ptf-md": "4.375rem" }}
      />
    </section>
  );
};

export default PricePlan;
