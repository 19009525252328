import React from "react";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarContent,
} from "react-pro-sidebar";
import Social from "../../Social/Social";
import { menuContent } from "../../../fake-data/MobileData";
import { menuContentLogin } from "../../../fake-data/MobileDataLogin";
import { shallowEqual, useSelector } from "react-redux";

const MobileMenu = ({ closeMenu }) => {
  const { user } = useSelector((state) => state.auth, shallowEqual);

  return (
    <>
      <div className="ptf-offcanvas-menu__navigation">
        <ProSidebar>
          <SidebarContent>
            <Menu className="sidebar-menu_wrapper">
              {(user ? menuContent : menuContentLogin).map((item, i) => (
                <div key={i}>
                  {item.dropDownItems?.length > 0 ? (
                    <SubMenu title={item.name} key={i}>
                      {item.dropDownItems?.map((val, index) => (
                        <MenuItem key={index}>
                          <Link to={val.routerPath}>{val.name}</Link>
                        </MenuItem>
                      ))}
                    </SubMenu>
                  ) : (
                    <MenuItem className="sidebar-menu">
                      <Link to={item.routerPath}>{item.name}</Link>
                    </MenuItem>
                  )}
                </div>
              ))}
            </Menu>
          </SidebarContent>
        </ProSidebar>
      </div>

      <div className="ptf-offcanvas-menu__footer">
        <p className="ptf-offcanvas-menu__copyright">
          @{new Date().getFullYear()} <span>Githubit</span>. All Rights
          Reserved.
        </p>
        <Social closeMenu={closeMenu} />
      </div>
    </>
  );
};

export default MobileMenu;
