import React from "react";
import PricePlan from "../../components/PricePlan";

const pricing = [
  {
    title: "Plan 0",
    price: "150",
    description: "Setup Admin and Store panel on your hosting.",
  },
  {
    title: "Plan 1",
    price: "400",
    description:
      "Setup Admin and Store panel on your hosting. Link user, POS system and deliveryman apps with your admin panel. Customize app name, logo, a color, package name, splash screen, etc. Setup OTP configuration.",
  },
  {
    title: "Plan 2",
    price: "600",
    description:
      "Plan 1 + Configure push notification. Setup payment gateways. Third party API setup (google map API setup included).",
  },
  {
    title: "Plan 3",
    price: "800",
    description:
      "Plan 2 + Translate all applications to your local language. Upload user, store and delivery man apps to Google Play Store. [Optional] Upload apps to Apple Store (+$200 per app for Apple store release).",
  },
  {
    title: "Plan 4",
    price: "1100",
    description:
      "Plan 3 + Upload All apps to the Apple Store. One revision before project handover.",
  },
];

const SupportPlan = () => {
  return (
    <div
      className="introduction "
      data-aos="fade-right"
      data-aos-delay="300"
      data-aos-duration="1000"
    >
      <h1 className="title">Support</h1>
      <h3 className="introduction-contentTitle">
        Licenses, activation and customization
      </h3>
      <div>
        After you purchased our product and installed it, please reach out to us
        via Telegram (1 202 340 1032) or WhatsApp (1 202 340 1032) to schedule
        activation. Please note that once you have downloaded the item, we will
        not issue a refund.
      </div>
      <h3 className="introduction-contentTitle">
        Holders of a regular license:
      </h3>
      <div>
        We are happy to respond if you have one or two specific questions but
        can’t provide extensive support and guidance for free. No support is
        provided to set up your server or flutter environment in your local
        device (iOS or Android) installation. To receive installation support,
        you need to purchase one of our support plans.
        <br />
        Please check our documentation before you purchase the item if you want
        to install it on your own. We are continually striving to improve
        documentation, so if you are unhappy with the current state of
        documentation, please check in later, as an updated version might suit
        your installation needs better. Please note that once you have
        downloaded the item, we will not issue a refund. Please also note that
        some of the features such as subscription are only available under the
        extended license.
        <br />
        Holders of an extended license
        <br />
        Please reach out to us if you have questions.
      </div>
      <h3 className="introduction-contentTitle">Customization</h3>
      <div>
        Our team is available to help with customization. Please reach out to us
        with detailed information so that our team can give you a quote.
        <br />
        If our team has not done the customization, we cannot help you with any
        potential issues that may arise from your customization.
      </div>

      <PricePlan data={pricing} />
    </div>
  );
};

export default SupportPlan;
