// export const sundaymartDocMenu = [
//   {
//     name: "Introduction",
//     routerPath: "/sundaymart-documentation/introduction",
//   },
//   {
//     name: "Prerequisite",
//     dropDownItems: [
//       {
//         name: "Basic",
//         routerPath: "/sundaymart-documentation/basic",
//       },
//       {
//         name: "Backend",
//         routerPath: "/sundaymart-documentation/server",
//       },
//       {
//         name: "Frontend",
//         routerPath: "/sundaymart-documentation/front",
//       },
//       {
//         name: "Mobile app",
//         routerPath: "/sundaymart-documentation/mobile-app",
//       },
//     ],
//   },
//   {
//     name: "Local environment Setup",
//     dropDownItems: [
//       {
//         name: "Mobile app",
//         routerPath: "/sundaymart-documentation/flutter-sdk",
//       },
//       {
//         name: "Frontend",
//         routerPath: "/sundaymart-documentation/local-front",
//       },
//       {
//         name: "Backend",
//         routerPath: "/sundaymart-documentation/local-server",
//       },
//     ],
//   },
//   {
//     name: "Admin Panel",
//     dropDownItems: [
//       {
//         name: "Install on server",
//         routerPath: "/sundaymart-documentation/install-on-server",
//       },
//       {
//         name: "Mandatory setup",
//         routerPath: "/sundaymart-documentation/mandatory-setup",
//       },
//       {
//         name: "Customization",
//         routerPath: "/sundaymart-documentation/customization",
//       },
//       {
//         name: "Troubleshooting",
//         routerPath: "/sundaymart-documentation/troubleshooting",
//       },
//     ],
//   },
//   {
//     name: "Mobile Apps",
//     dropDownItems: [
//       {
//         name: "Mandatory setup",
//         routerPath: "/sundaymart-documentation/mandatory-setup-mobile",
//       },
//       {
//         name: "Customization",
//         routerPath: "/sundaymart-documentation/customization-mobile",
//       },
//       {
//         name: "App build & release",
//         routerPath: "/sundaymart-documentation/app-build-release",
//       },
//     ],
//   },
//   {
//     name: "Web App",
//     dropDownItems: [
//       {
//         name: "Mandatory setup",
//         routerPath: "/sundaymart-documentation/mandatory-setup-web",
//       },
//       {
//         name: `Build code and setup on server`,
//         routerPath: "/sundaymart-documentation/build-code-and-setup-on-server",
//       },
//     ],
//   },
//   {
//     name: "Firebase setup",
//     routerPath: "/sundaymart-documentation/firebase",
//   },
//   {
//     name: "Update",
//     dropDownItems: [
//       {
//         name: "Admin Panel",
//         routerPath: "/sundaymart-documentation/update-admin-panel",
//       },
//       {
//         name: "App & Web",
//         routerPath: "/sundaymart-documentation/Update-app-web",
//       },
//     ],
//   },
//   {
//     name: "Support plan",
//     routerPath: "/sundaymart-documentation/support-plan",
//   },
// ];

export const sundaymartDocMenu = [
  {
    name: "Introduction",
    dropDownItems: [
      {
        name: "Introduction",
        routerPath: "/sundaymart-documentation/introduction",
      },
      {
        name: "Server requirements",
        routerPath: "/sundaymart-documentation/recommendations",
      },
    ],
  },
  {
    name: "Backend Api",
    dropDownItems: [
      {
        name: "Requirements",
        routerPath: "/sundaymart-documentation/server",
      },
      {
        name: "Installation",
        routerPath: "/sundaymart-documentation/mandatory-setup-backend",
      },
      {
        name: "Troubleshooting",
        routerPath: "/sundaymart-documentation/troubleshooting-backend",
      },
    ],
  },
  {
    name: "Admin Panel",
    dropDownItems: [
      {
        name: "Requirements",
        routerPath: "/sundaymart-documentation/admin",
      },
      {
        name: "Installation",
        routerPath: "/sundaymart-documentation/install-on-server",
      },
      {
        name: "Troubleshooting",
        routerPath: "/sundaymart-documentation/troubleshooting-admin",
      },
    ],
  },
  {
    name: "Customer Website",
    dropDownItems: [
      {
        name: "Requirements",
        routerPath: "/sundaymart-documentation/front",
      },
      {
        name: "Installation",
        routerPath: "/sundaymart-documentation/mandatory-setup-web",
      },
      {
        name: "Change page's static data",
        routerPath: "/sundaymart-documentation/change-static-data",
      },
      // {
      //   name: "Troubleshooting",
      //   routerPath: "/",
      // },
    ],
  },
  {
    name: "Customer App",
    dropDownItems: [
      {
        name: "Requirements",
        routerPath: "/sundaymart-documentation/mobile-app",
      },
      {
        name: "Installation",
        routerPath: "/sundaymart-documentation/mandatory-setup-customer",
      },
      {
        name: "Customization",
        routerPath: "/sundaymart-documentation/customization-customer",
      },
      {
        name: "App build & release",
        routerPath: "/sundaymart-documentation/customer-app-build-release",
      },
      // {
      //   name: "Troubleshooting",
      //   routerPath: "/",
      // },
    ],
  },
  {
    name: "Vendor App",
    dropDownItems: [
      {
        name: "Requirements",
        routerPath: "/sundaymart-documentation/vendor-app",
      },
      {
        name: "Installation",
        routerPath: "/sundaymart-documentation/mandatory-setup-vendor",
      },
      {
        name: "Customization",
        routerPath: "/sundaymart-documentation/customization-vendor",
      },
      {
        name: "App build & release",
        routerPath: "/sundaymart-documentation/vendor-app-build-release",
      },
      // {
      //   name: "Troubleshooting",
      //   routerPath: "/",
      // },
    ],
  },
  {
    name: "Driver App",
    dropDownItems: [
      {
        name: "Requirements",
        routerPath: "/sundaymart-documentation/driver-app",
      },
      {
        name: "Installation",
        routerPath: "/sundaymart-documentation/mandatory-setup-deliveryboy",
      },
      {
        name: "Customization",
        routerPath: "/sundaymart-documentation/customization-deliveryboy",
      },
      {
        name: "App build & release",
        routerPath: "/sundaymart-documentation/deliveryboy-app-build-release",
      },
      // {
      //   name: "Troubleshooting",
      //   routerPath: "/",
      // },
    ],
  },
  {
    name: "Pos App",
    dropDownItems: [
      {
        name: "Requirements",
        routerPath: "/sundaymart-documentation/pos-app",
      },
      {
        name: "Installation",
        routerPath: "/sundaymart-documentation/mandatory-setup-pos",
      },
      {
        name: "Customization",
        routerPath: "/sundaymart-documentation/customization-pos",
      },
      {
        name: "App build & release",
        routerPath: "/sundaymart-documentation/pos-app-build-release",
      },
      // {
      //   name: "Troubleshooting",
      //   routerPath: "/",
      // },
    ],
  },
  {
    name: "Firebase setup",
    routerPath: "/sundaymart-documentation/firebase",
  },
  {
    name: "Update",
    routerPath: "/sundaymart-documentation/update",
  },
];
