import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearUser } from "../../redux/slices/auth";

const SocialShare = [
  {
    iconName: "socicon-linkedin",
    link: "https://www.linkedin.com/company/githubit",
    iconClass: "linkedin-icon",
  },
  {
    iconName: "socicon-facebook",
    link: "https://facebook.com/githubit",
    iconClass: "facebook",
  },
];

const Social = ({ closeMenu = () => {} }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth, shallowEqual);

  const logoutUser = (event) => {
    event.preventDefault();
    localStorage.removeItem("token");
    dispatch(clearUser());
    closeMenu();
    navigate("/");
  };

  return (
    <div className="ptf-offcanvas-menu__socials align-items-center">
      {SocialShare.map((icon, i) => (
        <a
          className={`ptf-social-icon ptf-social-icon--style-4 ${icon.iconClass}`}
          href={icon.link}
          target="_blank"
          rel="noopener noreferrer"
          key={i}
        >
          <i className={icon.iconName}></i>
        </a>
      ))}
      {user && (
        <div
          className="ptf-offcanvas-menu__header d-flex justify-content-end"
          style={{ flexGrow: 1 }}
        >
          <div className="ptf-language-switcher">
            <div className="ptf-submit-button">
              <a href="/logout" onClick={logoutUser}>
                Logout
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Social;
