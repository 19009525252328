import React from "react";
import { Link } from "react-router-dom";

const Pricing = ({ data = [] }) => {
  return (
    <>
      {data.map((val, i) => (
        <div className="col-xl-4 col-lg-6 mb-5" key={i}>
          <div
            className="ptf-animated-block h-100"
            data-aos="fade"
            data-aos-delay={i * 100}
          >
            <div className="ptf-pricing-table h-100">
              {i === 2 ? <div className="badge">Popular</div> : ""}
              <div className="ptf-pricing-table__header">
                <h4 className="ptf-pricing-table__title">{val.title}</h4>
              </div>
              <div className="ptf-pricing-table__price">
                <span className="currency">$</span>
                <span className="price">{val.price}</span>
                <span className="period">/ Month</span>
              </div>
              <div className="ptf-pricing-table__description">Month</div>
              <div
                className="ptf-pricing-table__content"
                dangerouslySetInnerHTML={{ __html: val.description }}
              />
              <div className="ptf-pricing-table__action">
                <Link
                  className="ptf-btn ptf-btn--primary ptf-btn--block"
                  to="/login"
                  onClick={(event) => event.preventDefault()}
                >
                  Purchase
                </Link>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default Pricing;
