import React from "react";
import FooterList from "../List/FooterList";
import { Link } from "react-router-dom";

const FooterThreeDark = () => {
  return (
    <div className="row footer-back">
      <div className="col-12 col-lg">
        <div className="ptf-animated-block" data-aos="fade" data-aos-delay="0">
          <Link to="/">
            <h2 className="text-Logo">Githubit</h2>
          </Link>
        </div>
      </div>

      <div className="col-12 col-lg">
        <div
          className="ptf-animated-block"
          data-aos="fade"
          data-aos-delay="100"
        >
          <div className="ptf-widget ptf-widget-links has-white-color">
            <FooterList />
          </div>
        </div>
      </div>

      <div className="col-12 col-lg">
        <div
          className="ptf-animated-block"
          data-aos="fade"
          data-aos-delay="200"
        >
          <div className="ptf-widget ptf-widget-text">
            <a className="fz-36 has-color" href="mailto:support@githubit.com">
              support@githubit.com
            </a>
            <br />
            <a className="fz-40 has-color" href="tel:+12023401032">
              +1 202 3401032
            </a>
            <div className="ptf-spacer" style={{ "--ptf-xxl": "0.625rem" }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterThreeDark;
