import { createContext, useContext, useState } from "react";
import {
  GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider,
} from "firebase/auth";
import { auth } from "../service/FireBase";

export const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const theme = localStorage.getItem("theme");
  const [darkTheme, setDarkTheme] = useState(theme === "");

  function googleSignIn() {
    const googleAuthProvider = new GoogleAuthProvider();
    return signInWithPopup(auth, googleAuthProvider);
  }

  function facebookSignIn() {
    const facebookAuthProvider = new FacebookAuthProvider();
    return signInWithPopup(auth, facebookAuthProvider);
  }

  return (
    <AuthContext.Provider
      value={{ googleSignIn, facebookSignIn, setDarkTheme, darkTheme }}
    >
      {children}
    </AuthContext.Provider>
  );
}
