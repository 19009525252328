import React, { useState } from "react";
import { Link } from "react-router-dom";
import MobileMenu from "./Menu/MobileMenu";
import DarkMode from "../DarkMode";
import { FaUserEdit } from "react-icons/fa";
import { AiOutlineLogout } from "react-icons/ai";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { clearUser } from "../../redux/slices/auth";

const HeaderDark = () => {
  const dispatch = useDispatch();
  const [menu, setMenu] = useState(false);
  const { user } = useSelector((state) => state.auth, shallowEqual);
  const handleClickMenu = () => {
    setMenu(!menu);
    setOpenUser(false);
  };
  const [openUser, setOpenUser] = useState(false);

  const handleClickUser = () => setOpenUser(!openUser);

  const handleLogout = () => dispatch(clearUser());

  return (
    <>
      <header className="ptf-header--style-5 ptf-header--opaque">
        <div className={"ptf-navbar ptf-navbar--main ptf-navbar--sticky"}>
          <div className="container-xxl">
            <div className="ptf-navbar-inner">
              <Link className="ptf-navbar-logo" to="/">
                <h2 className="text-Logo">Githubit</h2>
              </Link>

              <span className="d-flex justify-content-center align-items-center">
                <span>
                  <DarkMode />
                </span>
                <div
                  className="ptf-offcanvas-menu-icon js-offcanvas-menu-toggle bar right"
                  onClick={handleClickMenu}
                >
                  <i className="lnir lnir-menu-alt-5" />
                </div>
              </span>
            </div>
          </div>
        </div>
      </header>

      <div
        className={menu ? "ptf-offcanvas-menu is-open" : "ptf-offcanvas-menu "}
      >
        <div className="ptf-offcanvas-menu__header">
          {user ? (
            <div className="user-container">
              <img
                src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img (32).webp"
                className="rounded-circle"
                height="50"
                width="50"
                alt="Avatar"
                loading="lazy"
                onClick={handleClickUser}
              />
              <div
                className="user-dropdown-menu"
                style={{ display: openUser ? "block" : "none" }}
              >
                <Link to="/user-profile" className="dropdown-item">
                  <FaUserEdit className="dropdown-icon" />{" "}
                  <span>My profile</span>
                </Link>

                <Link to="/" className="dropdown-item" onClick={handleLogout}>
                  <AiOutlineLogout className="dropdown-icon" />
                  <span>Logout</span>
                </Link>
              </div>
            </div>
          ) : null}
          <span
            className="ptf-offcanvas-menu-icon js-offcanvas-menu-toggle"
            onClick={handleClickMenu}
          >
            <i className="lnir lnir-close" />
          </span>
        </div>
        <MobileMenu closeMenu={handleClickMenu} />
      </div>
    </>
  );
};

export default HeaderDark;
